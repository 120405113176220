<script>
import Layout from "../../../../../layouts/main";
import appConfig from "@/app.config";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      salaryItems: [],
      account: {
        name: "",
        name_en: "",
        b_name: "",
        b_name_en: "",
        account: "",
      },
      levelThreeAccounts: [],
      branch: {},
      branchItem: {
        isNew: 1,
        item: {},
        code: null,
        account: null,
      },
    };
  },
  methods: {
    selectedAccount(account) {
      this.http
        .post("cash-accounts/level-three/search", {
          search: account,
          module_name: "Banks",
        })
        .then((res) => {
          if (res.status) {
            this.levelThreeAccounts = res.data.map((a) => {
              return this.$i18n.locale == "ar"
                ? a.code + "-" + a.name
                : a.code + "-" + a.name_en;
            });
          }
        });
    },
    addItem() {
      console.log(this.account);
      this.http
        .put("cash-accounts", parseInt(this.$route.params.id), this.account)
        .then((res) => {
          if (res.status) {
            this.$router.push("/cash_and_banks");
          }
        });
    },
    getAccount() {
      console.log("requesting ....");
      this.http
        .post("cash-accounts/edit", { id: this.$route.params.id })
        .then((res) => {
          if (res.status) {
            this.account = res.data[0];
            this.account.account = "";
            //     this.$i18n.locale == "ar"
            //       ? (this.account.account =
            //           res.data[0]?.code + "-" + res.data[0]?.l2_name)
            //       : res.data[0]?.code + "-" + res.data[0]?.l2_name_en;
          }
        });
    },
  },
  computed: {
    filteredAccounts() {
      return this.levelThreeAccounts.filter((account) => {
        return account;
      });
    },
  },
  created() {
    this.getAccount();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="row align-items-center">
        <div class="d-flex align-items-center mb-4">
          <router-link :to="`/cash_and_banks`">
            <h4 class="m-0">
              {{ $t("cash_banks.title") }}
            </h4>
          </router-link>
          <div class="mx-2">\</div>
          <div>
            {{
              $t("cash_banks.edit") +
              ` (${
                $i18n.locale == "ar" ? account?.b_name : account?.b_name_en
              })`
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row p-2" style="background-color: rgb(239, 237, 237)">
              <form class="mb-3" @submit.prevent="addItem">
                <div class="mb-3">
                  <label for="">{{ $t("popups.name_ar") }}</label>
                  <input
                    v-model="account.name"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="mb-3">
                  <label for="">{{ $t("popups.name_en") }}</label>
                  <input
                    v-model="account.name_en"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="mb-3">
                  <label for="">{{ $t("cash_banks.number") }}</label>
                  <input
                    v-model="account.account_number"
                    type="number"
                    class="form-control"
                  />
                </div>

                <div class="mb-3">
                  <label for="">{{ $t("popups.code") }}</label>
                  <input
                    v-model="account.code"
                    required
                    disabled
                    type="number"
                    class="form-control"
                  />
                </div>

                <div class="mb-3">
                  <label for="">{{ $t("salaryItems.searchAccount") }}</label
                  ><br />
                  <AutoComplete
                    @keyup="selectedAccount(account.account)"
                    v-model="account.account"
                    :suggestions="filteredAccounts"
                    @complete="account.account = $event.query"
                    style="
                      color: #333 !important;
                      border-radius: 10px !important;
                    "
                  />
                </div>

                <hr class="mt-2" />
                <div
                  class="float-end"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                >
                  <router-link to="/cash_and_banks">
                    <button
                      style="margin-inline-end: 4px"
                      type="button"
                      class="btn btn-secondary"
                    >
                      {{ $t("popups.cancel") }}
                    </button>
                  </router-link>
                  <button type="submit" class="btn btn-primary">
                    {{ $t("popups.edit") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped></style>
